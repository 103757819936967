import { Delete, Edit, EyeOff } from "lucide-react";
import { useEffect, useState } from "react";



  interface Game {
    id: number;
    name: string;
    minInvest: number;
    maxInvest: number;
    status: 'Enabled' | 'Disabled';
  }
  
  const sampleData: Game[] = [
    {
        "id": 1,
        "name": "Black Jack",
        "minInvest": 5.00,
        "maxInvest": 1000.00,
        "status": "Enabled"
      },
      {
        "id": 2,
        "name": "Classic Dice",
        "minInvest": 10.00,
        "maxInvest": 5000.00,
        "status": "Enabled"
      },
      {
        "id": 3,
        "name": "Crash",
        "minInvest": 1.00,
        "maxInvest": 500.00,
        "status": "Enabled"
      },
      {
        "id": 4,
        "name": "Diamond",
        "minInvest": 0.50,
        "maxInvest": 100.00,
        "status": "Disabled"
      },
      {
        "id": 5,
        "name": "Goal",
        "minInvest": 25.00,
        "maxInvest": 10000.00,
        "status": "Enabled"
      },
      {
        "id": 6,
        "name": "Hash Dice",
        "minInvest": 5.00,
        "maxInvest": 2000.00,
        "status": "Enabled"
      },
      {
        "id": 7,
        "name": "High Low",
        "minInvest": 1.00,
        "maxInvest": 200.00,
        "status": "Enabled"
      },
      {
        "id": 8,
        "name": "Hilo",
        "minInvest": 0.25,
        "maxInvest": 50.00,
        "status": "Disabled"
      },
      {
        "id": 9,
        "name": "Keno",
        "minInvest": 1.00,
        "maxInvest": 20.00,
        "status": "Enabled"
      },
      {
        "id": 10,
        "name": "Limbo",
        "minInvest": 2.00,
        "maxInvest": 1000.00,
        "status": "Enabled"
      },
      {
        "id": 11,
        "name": "Magical Wheel",
        "minInvest": 10.00,
        "maxInvest": 3000.00,
        "status": "Enabled"
      },
      {
        "id": 12,
        "name":"Mine",
        "minInvest": 5.00,
        "maxInvest": 1500.00,
        "status": "Enabled"
      },
      {
        "id": 13,
        "name": "Plinko",
        "minInvest": 3.00,
        "maxInvest": 1000.00,
        "status": "Enabled"
      },
      {
        "id": 14,
        "name": "Roulette",
        "minInvest": 5.00,
        "maxInvest": 2000.00,
        "status": "Enabled"
      },
      {
        "id": 15,
        "name": "Single Keno",
        "minInvest": 1.00,
        "maxInvest": 200.00,
        "status": "Disabled"
      },
      {
        "id": 16,
        "name": "SnakeAndLadders",
        "minInvest": 2.00,
        "maxInvest": 500.00,
        "status": "Enabled"
      },
      {
        "id": 17,
        "name": "Three Card Montre",
        "minInvest": 0.50,
        "maxInvest": 100.00,
        "status": "Enabled"
      },
      {
        "id": 18,
        "name": "Tower",
        "minInvest": 1.00,
        "maxInvest": 1000.00,
        "status": "Enabled"
      },
      {
        "id": 19,
        "name": "Video Poker",
        "minInvest": 1.00,
        "maxInvest": 300.00,
        "status": "Disabled"
      },
      {
        "id": 20,
        "name": "Wheel",
        "minInvest": 1.00,
        "maxInvest": 500.00,
        "status": "Enabled"
      }
  ];
  
  const Game: React.FC = () => {
    const [games, setGames] = useState<Game[]>([]);
    
    useEffect(() => {
      setGames(sampleData);
    }, []);
  

    const toggleStatus = (id: number) => {
        setGames(prevGames =>
          prevGames.map(game =>
            game.id === id ? { ...game, status: game.status === 'Enabled' ? 'Disabled' : 'Enabled' } : game
          )
        );
      };
      
    return (
      <div className='ml-0 lg:ml-[256px]'>
            <h1 className="text-3xl font-bold mb-8 text-white">Games</h1>

        <div className="overflow-x-auto overflow-y-auto max-h-[70vh]">
          <table className="min-w-full bg-white rounded-lg">
            <thead className="bg-blue-800 rounded-lg text-white sticky top-0">
              <tr>
                <th className="py-3 px-4 text-left text-sm font-semibold">Game Name</th>
                {/* <th className="py-3 px-4 text-left text-sm font-semibold">Minimum Invest</th>
                <th className="py-3 px-4 text-left text-sm font-semibold">Maximum Invest</th> */}
                <th className="py-3 px-4 text-left text-sm font-semibold">Status</th>
                <th className="py-3 px-4 text-left text-sm font-semibold">Action</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {games.map((game) => (
                <tr key={game.id}>
                  <td className="py-3 px-4 text-sm text-gray-800">{game.name}</td>
                  {/* <td className="py-3 px-4 text-sm text-gray-800">${game.minInvest.toFixed(2)}</td>
                  <td className="py-3 px-4 text-sm text-gray-800">${game.maxInvest.toFixed(2)}</td> */}
                  <td className="py-3 px-4">
                    <span className={`px-2 py-1 text-xs font-semibold rounded-full ${
                      game.status === 'Enabled' 
                        ? 'text-green-800 bg-green-100' 
                        : 'text-red-800 bg-red-100'
                    }`}>
                      {game.status}
                    </span>
                  </td>
                  <td className="py-3 px-4 flex gap-2 items-center">
                  <td className="py-3 px-4 flex gap-2 items-center">
  <div className="p-1 border border-red-600 rounded-md w-[100px] flex gap-2 items-center">
    <EyeOff size={15} className="ml-2 text-red-600 hover:text-red-800" onClick={() => toggleStatus(game.id)} />
    <button className="ml-2 text-red-600 hover:text-red-800" onClick={() => toggleStatus(game.id)}>
      {game.status === 'Enabled' ? 'Disable' : 'Enable'}
    </button>
  </div>
</td>


                   
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };
  
  export default Game;